.menuopt2 {
    --bg1: rgba(249, 249, 249, 0.9);
    --bg1a: rgba(248, 248, 248, 0.96);
    --bg2: rgba(0, 0, 0, 0.08);
    --gray-txt: #555;
    --com2-txt: rgb(207, 207, 207);
    --comp-txt: #ddd;

    .minimenu2 {
        position: absolute;
        display: none;
        top: 160%;
        // left: -80%;
        width: 120px;
        // left: 30%;
        background: var(--comp-txt);
        box-shadow: 0px 3px 6px 1px var(--comp-txt);
        z-index: 9999;
        padding: 4px 0;
        border: solid 1px var(--com2-txt);
        // animation: fadein 600ms ease-in-out;
        // backdrop-filter: saturate(2) blur(20px);
        border-radius: 8px;
        &:hover {
            display: inline;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.08);
        .minimenu2 {
            display: inline;
        }
    }
    margin: '0px 20px 0px 20px'
}

.hoverBlue {
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff;
    &:hover {
        background: #f0f2f5;
        border-style: solid;
        border-width: 1px;
        border-color: #b5c9f5;
        .hoverMenu {
            display: inline;
        }
    }
    .hoverMenu {
        display: none;
    }
}

// .minimenu2 {
//     position: absolute;
//     display: none;
//     top: 160%;
//     // left: -80%;
//     width: 120px;
//     // left: 30%;
//     background: var(--comp-txt);
//     box-shadow: 0px 3px 6px 1px var(--comp-txt);
//     z-index: 9999;
//     padding: 4px 0;
//     border: solid 1px var(--com2-txt);
//     // animation: fadein 600ms ease-in-out;
//     // backdrop-filter: saturate(2) blur(20px);
//     border-radius: 8px;
//     &:hover {
//         display: inline;
//     }
//   }

.deafulBlue {
    background: #f0f2f5;
    border-style: solid;
    border-width: 1px;
    border-color: #b5c9f5;
}

.toolbaeMenu {
    margin: 15px 0 20px 0
  
}
.toRight >*{
    position: relative;
    right: 0;
    float: right
}
.addrBarSearch {
    position: 'absolute';
    right: '0px'
}

.tbmiat > *{
    margin: 0 20px 0 20px
  
}