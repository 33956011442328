.toolbar{
  display: flex;
  width: 100%;
  height: 26px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // background: inherit;

  &[data-float="false"]{
    z-index: 10;
  }

  &[data-back="light"]{
    background: #fefefe;
  }

  &[data-back="dark"]{
    background: #181818;
  }
}

.topInfo{
  height: 100%;

  &[data-float="true"]{
    opacity: 0;
  }

  .uicon{
    margin: 0 10px 0 6px;
  }
}

.actbtns{
  height: 100%;

  .uicon{
    height: 100%;
    padding: 0 14px;
    transition: all ease-in-out 60ms;

    img{
      transition: all ease-in-out 60ms;
    }

    &:hover{
      background: rgba(136, 136, 136, 0.2);
    }

    &[data-payload="close"]:hover{
      background: rgba(255, 0, 0, 0.8);
      img{
        filter: invert(1);
      }
    }
  }
}

.snapbox{
  position: relative;

  &[data-hv="true"]{
    background: rgba(136, 136, 136, 0.2);
  }
}

.snapcont{
  box-sizing: border-box;
  width: 260px;
  height: 130px;
  position: absolute;
  display: grid;
  right: -24px;
  grid-template-columns: repeat(3,minmax(0,1fr));
  grid-template-rows: repeat(2,minmax(0,1fr));
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  z-index: 10;
  padding: 8px;
  background: rgb(250, 250, 250);
  border: solid 1px rgba(150, 150, 150, 0.2);
  border-radius: 6px;
  transform-origin: center;
  animation: popup 1s ease-in-out;

  .snapper{
    background: #e6e6e6;
    border: solid 1px #999;
    &:hover{
      background: #0066ff;
    }
  }

  &[data-dark="true"]{
    background: #222;

    .snapper{
      background: #444;
      border: solid 1px #333;
      &:hover{
        background: #0066ff;
      }
    }
  }
}

@keyframes popup{
  0%{
    opacity: 0;
  }

  90%{
    opacity: 0;
    transform: scale(0.8);
  }

  100%{
    opacity: 1;
    transform: scale(1);
  }
}

.snapLay{
  display: grid;
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  &:nth-child(1){
    grid-template-columns: auto auto;
  }

  &:nth-child(2){
    grid-template-columns: auto auto auto;

    .snapper:first-child{
      grid-column-end: span 2;
    }
  }

  &:nth-child(3){
    grid-template-columns: auto auto auto;
  }

  &:nth-child(4){
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;

    .snapper:first-child{
      grid-row: 1 / span 2;
    }
  }

  &:nth-child(5){
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 4px;
  }

  &:nth-child(6){
    grid-template-columns: auto auto auto auto auto auto auto;

    .snapper{
      grid-column-end: span 2;
    }

    .snapper:nth-child(2){
      grid-column: 3 / span 3;
    }
  }
}

.snapper{
  box-sizing: border-box;
  transition: all ease-in-out 100ms;
}

.uicon{
  position: relative;
  display: grid;
  place-items: center;
}

.imageCont{
  position: relative;
  display: grid;
  place-items: center;
  width: auto;
  height: auto;

  &[data-back="true"]{
    background-position: center;
    background-size: cover;
  }

  img[data-free="false"]{
    max-width: 100%;
    max-height: 100%;
  }
}

.imageCont.rounded{
  overflow: hidden;
}

body[data-theme="dark"] .toolbar[data-noinvert="false"]{

  .snapcont{
    background: #222;

    .snapper{
      background: #444;
      border: solid 1px #333;
      &:hover{
        background: #0066ff;
      }
    }
  }

  .appFullName{
    color: #fefefe;
  }

  .actbtns{
    .uicon img{
      filter: invert(1);
    }
  }
}

.resizecont{
  // height: 100%;
  position: absolute;
  z-index: 12;
  // box-sizing: border-box;
  // border: solid 5px red;
}

.topone{
  top: -8px;
  left: 0;
  width: 80%;
  z-index: 22;
}

.leftone{
  left: -8px;
  height: 100%;
}

.rightone{
  right: -8px;
  bottom: 0;
  height: 90%;
}

.bottomone{
  bottom: -8px;
  left: 0;
  width: 100%;
}

.conrsz{
  width: 8px;
  height: 8px;
  // background: rgba(238, 21, 21, 0.4);
}

.edgrsz{
  min-width: 8px;
  min-height: 8px;
  // background: rgba(21, 54, 238, 0.4);
}

.wdws{
  width: 100%;
}

.hdws{
  height: 100%;
}
